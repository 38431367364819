import React, {lazy, useEffect} from 'react';
import './App.module.scss';
import s from './App.module.scss';
import setFullHeight from "./utils/setFullHeight";

const MainPage = lazy(() => import('./pages/main-page/MainPage'));

const App = () => {
  useEffect(() => {
    window.addEventListener('resize', setFullHeight);
    setFullHeight();

    return () => {
      window.removeEventListener('resize', setFullHeight);
    };
  }, []);

  return (
    <div className={s.App}>
      <MainPage/>
    </div>
  );
}

export default App;
